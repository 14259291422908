import React from 'react'
import About from "./Layouts/About";
import Blitz from "./Layouts/Blitz";
import Comingsoon from "./Layouts/Comingsoon";
import Contact from "./Layouts/Contact";
import Hero from "./Layouts/Hero";
import Ready from "./Layouts/Ready";
import Service from "./Layouts/Service";
import ShowReel from './Layouts/ShowReel';
import Team from './Layouts/Team';

export default function Home() {
  return (
    <>
        <Hero />
        <div className="content" style={{marginTop:0}}>
          <Blitz />
          <About />
          <Service />
          <Comingsoon />
          <Team/>
          <Contact />
          <Ready />
        </div>
    </>
  )
}
