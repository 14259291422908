import Footer from "./Layouts/Footer";
import Header from "./Layouts/Header";
import Nav from "./Layouts/Nav";
import Share from "./Layouts/Share";
import Home from "./Home";

function App() {
  return (
    <>
      <Header />
      <div id="wrapper">
        <Nav />
        <Home />
        <Footer />
        <Share />
      </div>
    </>
  )
}

export default App;
