import React from 'react'

export default function Hero() {
  return (
    <>
    <div className="hero-wrap" id="sec1" data-scrollax-parent="true">
       <div className="slider-carousel-wrap full-height fullscreen-slider-wrap">
           <div className="fullscreen-slider full-height cur_carousel-slider-container fl-wrap" data-slick='{"autoplay": true, "autoplaySpeed": 4000 , "pauseOnHover": false}' style={{height:'100vh'}}>
               <div className="fullscreen-slider-item full-height fl-wrap">
                   <div className="bg par-elem"  data-bg="styles/images/blitz/15.jpg"></div>
                   <div className="overlay"></div>
                   <div className="half-hero-wrap">
                       <h1> 
                            <span> Blitz VFX </span><br />stays unraveled to INNOVATION,<br /> QUALITY & DEDICATION
                        </h1>
                       <div className="clearfix"></div>
                       <a href="#sec2" className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">Let's Start</a>
                   </div>
               </div>
               <div className="fullscreen-slider-item full-height fl-wrap">
                   <div className="bg par-elem"  data-bg="styles/images/blitz/17.jpg"></div>
                   <div className="overlay"></div>
                   <div className="half-hero-wrap">
                       <h1> Blitz VFX <br /> have a rich Legacy of <br />  <span>producing Exceptional visuals.</span></h1>
                       <div className="clearfix"></div>
                       <a href="#sec4" className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">My Portfolio</a>
                   </div>
               </div>
               <div className="fullscreen-slider-item full-height fl-wrap">
                   <div className="half-hero-wrap">
                       <h1><span>Blitz VFX </span> <br /> comprises community Of innovators <br />and industry leading artists</h1>
                        <div className="clearfix"></div>
                       <a href="#sec3" className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">My services</a>
                   </div>
                   <div className="bg par-elem"  data-bg="styles/images/blitz/18.jpg"></div>
                   <div className="overlay"></div>
               </div>
           </div>
           <div className="sp-cont sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
           <div className="sp-cont sp-cont-next"><i className="fal fa-arrow-right"></i></div>
           <div className="fullscreenslider-counter"></div>
       </div>
       <div className="hero-decor-numb"><span>40.7143528  </span><span>-74.0059731 </span> <a href="https://www.google.com.ua/maps/" target="_blank" className="hero-decor-numb-tooltip">Based In NewYork</a></div>
    </div>
    </>
  )
}
