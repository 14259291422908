import React from 'react'

export default function Contact() {
    const contact = (e) => {
        e.preventdDefault()
    }
  return (
    <>
<section className="blue-bg" id="sec5" data-scrollax-parent="true">
    <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Get in Touch<span>//</span></div>
    <div className="container">
        <div className="main-about" style={{marginBottom:'30px'}}>
            <h2>Contact<span> US</span></h2>
        </div>
        <div className="fl-wrap   mar-bottom">
            <div className="row">
                <div className="col-md-12">
                    <div className="features-box-container single-serv fl-wrap">
                        <div className="row">
                            <div className="features-box col-md-3">
                                <div className="time-line-icon">
                                    <i className="fal fa-mobile-android"></i>
                                </div>
                                <h3>Phone</h3>
                                <a href="tel:+919940390307" style={{fontSize:'12px',color:'#1e1e1e'}}>+91 99403 90307</a>
                            </div>
                           
                            <div className="features-box col-md-5">
                                <div className="time-line-icon">
                                    <i className="fal fa-compass"></i>
                                </div>
                                <h3>Location</h3>
                                <a target="_blank" href="https://goo.gl/maps/eU8RqxNxdME4DPsi9" style={{textAlign:'justify'}}>
                                    <div className='row'>
                                        <div className='col-md-1'>
                                            <img src='styles/images/india.png' style={{height:30}}/>
                                        </div>
                                        <div className='col-md-11'>
                                        <pre style={{fontSize:'12px',color:'#1e1e1e'}}>
                                            No  8, Vinayagam Complex 1st Floor,<br/>
                                            Thiruvalluvar street,<br/>
                                            Meenakshi amman Nagar, <br/>
                                            Alwarthirunagar, Valasaravakkam<br/>
                                            Chennai - 600087
                                        </pre>
                                        </div>
                                     </div>                                                                                            
                                </a>
                            </div>
                            <div className="features-box col-md-4">
                                <div className="time-line-icon">
                                    <i className="fal fa-envelope-open"></i>
                                </div>
                                <h3>Email</h3>
                                <a href="mailto:info@blitzvfx.com" style={{fontSize:'12px',color:'#1e1e1e'}}> info@blitzvfx.com</a>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="features-box col-md-3">
                                <a href="tel:+18252883752" style={{fontSize:'12px',color:'#1e1e1e'}}>+1 8252883752</a>
                            </div>
                           
                            <div className="features-box col-md-5">
                                <a target="_blank" href="https://goo.gl/maps/pkAEWkpiunbRihGq6" style={{textAlign:'justify'}}>
                                    <div className='row'>
                                        <div className='col-md-1'>
                                            <img src='styles/images/canada.png' style={{height:30}}/>
                                        </div>
                                        <div className='col-md-11'>
                                            <pre style={{fontSize:'12px',color:'#1e1e1e'}}>
                                                3102, 60 Panatella st, <br/>
                                                NW Calgary, Alberta,<br/>
                                                Canada, <br/>
                                                T3K 0M3.
                                            </pre>
                                        </div>
                                     </div>   
                                </a>
                            </div>
                            
                            <div className="features-box col-md-4">
                                <a href="mailto:palle.narendra@blitzvfx.com" style={{fontSize:'12px',color:'#1e1e1e'}}>palle.narendra@blitzvfx.com</a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
       
        <div className="fl-wrap mar-top">
            <div className="row">
                <div className="col-md-6">
                    <div className="fw-map-container fl-wrap mar-bottom" >
                        <div className="map-container">
                            <div id="singleMap" data-latitude="40.7143528" data-longitude="-74.0059731" data-maptitle="Out Location">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3886.7218984471524!2d80.18064211482282!3d13.05336569080227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDAzJzEyLjEiTiA4MMKwMTAnNTguMiJF!5e0!3m2!1sen!2sin!4v1678714336505!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div>
                        <div id="message"></div>
                        <div className="custom-form">
                            <fieldset>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label><i className="fal fa-user"></i></label>
                                        <input type="text" name="name" placeholder="Your Name *"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label><i className="fal fa-envelope"></i> </label>
                                        <input type="text" name="email" placeholder="Email Address *"/>
                                    </div>
                                    <div className="col-md-6">
                                        <label><i className="fal fa-mobile-android"></i> </label>
                                        <input type="text" name="phone" placeholder="Phone *"/>
                                    </div>
                                    <div className="col-md-6">
                                        <select name="subject" className="chosen-select sel-dec">
                                            <option>Subject</option>
                                            <option value="Order Project">Order Project</option>
                                            <option value="Support">Support</option>
                                            <option value="Other Question">Other Question</option>
                                        </select>
                                    </div>
                                </div>
                                <textarea name="comments" placeholder="Your Message:"></textarea>
                                <div className="clearfix"></div>
                                <button onClick={contact} type="submit" className="btn float-btn flat-btn color-btn">Send Message</button>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="bg-parallax-module" data-position-top="70"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
    <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
    <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
    <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
    <div className="sec-lines"></div>
</section>
    </>
 )

}
