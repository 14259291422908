import React from 'react'
import ReactCompareImage from 'react-compare-image';

export default function Team(){
    return(
        <>
                    <section data-scrollax-parent="true" id="sec6">
                        <div class="section-subtitle right-pos"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Project Title</div>
                        <div class="container">
                            <div class="team-box">
                                <div class="team-photo">
                                    {/* <div class="overlay"></div>
                                    <a href="team-single.html">Deatils</a> */}
                                    <img src="styles/images/Team/Arun.jpeg" alt="" class="respimg"/> 										
                                </div>
                                <div class="team-info">
                                    <h3 style={{fontSize:'20px'}}>Arunkumar</h3>
                                    <h4 style={{fontSize:'12px'}}>Executive Producer</h4>
                                    <p style={{fontSize:'12px'}}>Arun is a seasoned Creative Visual Effects Executive Producer with a rich background in both film and commercial projects. His expertise extends to building and leading VFX teams while effectively managing projects with a discerning eye cultivated through years of experience. Arun specializes in VFX project management, demonstrating proficiency in schedule and budget oversight, bid creation, and the development of creative and technical approaches. With over 15 years of industry experience, he has successfully led teams and delivered high-quality projects for renowned companies such as Sony Image Works, Moving Picture Company, Basilic Fly Studio & Fury FX, Canada.</p>
                                    <ul class="team-social">
                                        <li><a href="mailto:Arunkumar<arunkumar.p@blitzvfx.com>"><i class="fal fa-envelope-open"></i></a></li>
                                        {/* <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-vk"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>        
                            <div class="team-box">
                                <div class="team-photo">
                                    {/* <div class="overlay"></div>
                                    <a href="team-single.html">Deatils</a> */}
                                    <img src="styles/images/Team/Rakesh.jpeg" alt="" class="respimg"/> 										
                                </div>
                                <div class="team-info">
                                    <h3 style={{fontSize:'20px'}}>Rakesh</h3>
                                    <h4 style={{fontSize:'12px'}}>Head of Business Development</h4>
                                    <p style={{fontSize:'12px'}}>Rakesh, Heads our Business development is an alum of Centennial Canadian College (centennialcollege.ca), boasts an impressive 16+ years of experience in the film and television industry. His exceptional communication skills, evident in interactions with clients, CG supervisors, vendors, freelancers, and artists, have positioned him as a standout professional. His diverse portfolio spans numerous movies, encompassing both film and television productions. Throughout his career, Rakesh has collaborated with industry giants such as MPC (The Moving Picture Company), DQ Entertainment PVT LTD, ESH Media, and CG Mantra Digital Media Pvt. Ltd.</p>
                                    <ul class="team-social">
                                        <li><a href="mailto:Rakesh<rakesh.kali@blitzvfx.com>"><i class="fal fa-envelope-open"></i></a></li>
                                        {/* <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-vk"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>     
                            <div class="team-box">
                                <div class="team-photo">
                                    {/* <div class="overlay"></div>
                                    <a href="team-single.html">Deatils</a> */}
                                    <img src="styles/images/Team/Dinesh_Babu.jpeg" alt="" class="respimg"/> 										
                                </div>
                                <div class="team-info">
                                    <h3 style={{fontSize:'20px'}}>Dinesh Babu</h3>
                                    <h4 style={{fontSize:'12px'}}>Creative Head</h4>
                                    <p style={{fontSize:'12px'}}>Dinesh is a seasoned VFX professional who is the Creative Head at Blitz. His extensive experience enables him to lead and motivate teams to create innovative visual effects for a range of international projects. By incorporating his expertise and guidance to the team, we have been able to deliver high-quality and visually stunning content on time and within budget. We stay up-to-date on emerging trends in the visual effects industry due to his extensive knowledge of software and tools, combined with his passion for technology. The nurturing of a creative environment that flourishes ideas and talents with us has been possible due to his leadership style, which involves a collaborative and inclusive approach and effective communication. He has successfully led teams and delivered high quality for companies like Basilic Fly & Image Loom</p>
                                    <ul class="team-social">
                                        <li><a href="mailto:Dinesh Babu<dineshbabu.v@blitzvfx.com>"><i class="fal fa-envelope-open"></i></a></li>
                                        {/* <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-vk"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>                          
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>
        </>
    )
}