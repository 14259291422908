import React from 'react'

export default function Footer() {
  return (
    <>
    <div className="height-emulator fl-wrap" id="footer_ink"></div>
    <footer className="main-footer fixed-footer">
        <div className="footer-inner fl-wrap" style={{ backgroundImage: "url('styles/images/TPN2.jpg')",top:100,height:'100vh', backgroundSize: 'contain',backgroundPosition:'center center', backgroundRepeat:'no-repeat'}}>
            <div className="container">
                <div className="partcile-dec" data-parcount="90"></div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="footer-title fl-wrap">
                            <span>Blitz</span>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <img src="#" style={{height:'50vh',marginTop:50,marginRight:'10px'}}  alt="" />
                    </div>
                    {/* <div className="col-md-5">
                        <div className="footer-box fl-wrap">
                            <div className="twitter-swiper-container fl-wrap" id="twitts-container"></div>
                        </div>
                        <img src="styles/images/TPN.jpg" className='mt-5' style={{width:'300px',marginTop:50,marginRight:'10px',boxShadow: '20px 20px 20px 20px rgba(0, 0, 0, 0.3)'}}  alt="" />
                    </div> */}
                </div>
            </div>
        </div>

        <div className="subfooter fl-wrap">
            <div className="container">
                <div className="policy-box">
                    <span>&#169; Blitz 2022  /  All rights reserved. </span>
                </div>
                <a href="#" className="to-top color-bg"><i className="fal fa-angle-up"></i><span></span></a>
            </div>
        </div>
    </footer>
	
    <a style={{backgroundColor:'#f7f7f7',width:'100px',marginRight:30}} className="contact-btn color-bg scroll-link" href="#sec5">
        <img src="styles/images/TPN.jpg" style={{width:'100px',marginRight:'10px',boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.3)'}}  alt="" />
    </a>
    </>
  )
}
