import React from 'react'

export default function About() {
  return (
    <>
        <section className="dark-bg no-padding" style={{marginTop:0}} id="sec2" data-scrollax-parent="true">
            <div className="hidden-info-wrap-bg">
                <div className="bg-ser">
                </div>
            </div>
            <div className="hidden-info-wrap">
                <div className="hidden-info fl-wrap">
                    <div style={{fontWeight:'bold'}} className="hidden-info-title">About Blitz VFX</div>
                    <div className="hidden-works-list fl-wrap">
                        <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr="styles/images/blitz/33.jpg">
                            <div className="hidden-works-item-text">
                                <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Company</div>
                                <h3>Company</h3>
                                <p className="content-light" style={{color:'#ededed'}}>Blitz VFX stays unrivaled when it comes to innovation, high-quality work, and dedication so go ahead as
                                    much as you desire. With us on your side, get ready to be amazed by something that’s honestly going to
                                    blow your mind!</p>
                                <br />
                                <p className="content-light" style={{color:'#ededed'}}>At our core, we are dedicated to delivering exceptional imagery and visual effects to our customers.
                                    Through a combination of skillful craftsmanship, imaginative thinking, and cutting-edge technology, we
                                    are able to bring exceptional concepts and ideas of filmmakers to visual frames.</p>
                            </div>                            
                            <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
                            <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                            <div className="sec-lines"></div>
                        </div>

                        <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr="styles/images/blitz/26.jpg">
                            <div className="hidden-works-item-text">
                                <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Why Choose</div>
                                <h3>Why choose us</h3>
                                <p className="content-light" style={{color:'#ededed'}}>Blitz Vfx is a leading visual effects company with a rich legacy of producing exceptional images and
                                    sequences for some of the biggest Hollywood blockbusters, earning us multiple accolades from the
                                    industry. Our creative expertise is also evident in the advertising, television and Over-the-Top media
                                    sphere, where we bring enchantment to the ubiquitous screens that surround us</p>
                            </div>
                            <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                            <div className="sec-lines"></div>
                        </div>

                        <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr="styles/images/blitz/10.jpg" style={{height:'492px'}}>
                            <div className="hidden-works-item-text">
                                <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Our Team</div>
                                <h3>Our team</h3>
                                <p className="content-light" style={{color:'#ededed'}}>We are a community of innovators who operate in the realms of film, advertising, television, and Over-
                                    the-Top media sphere. Our proficiency and scope are broad, thanks to a team of gifted artists and
                                    producers. Our insatiable curiosity drives us to explore new frontiers, platforms, and mediums through
                                    which we can narrate compelling stories, constantly looking towards the future.</p>
                            </div>
                            <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                            <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
                            <div className="sec-lines"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fl-wrap limit-box"></div>
        </section>
    </>
  )
}
