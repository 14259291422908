import React from 'react'

export default function Blitz() {
  return (
    <>
        <section className="blue-bg sinsec-dec" data-scrollax-parent="true">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Blitz Service</div>
            <div className="container">
                <div className="section-title">
                    <h2>About <span>Us</span></h2>
                    <p className="content" style={{maxWidth: 'none'}}>We Blitz VFX, founded in 2022 is headquartered in Chennai.</p>
                    <p className="content" style={{maxWidth: 'none'}}>Blitz VFX is a cutting-edge visual effects company that specializes in creating high-quality special effects for film and television projects. With a team of experienced and talented artists and technicians, Blitz VFX has the skills and expertise to bring any imaginative vision to life on the big screen.</p>    
                    <p className="content" style={{maxWidth: 'none'}}>With a strong commitment to innovation and excellence, Blitz VFX is well-positioned to continue making a significant impact in the world of visual effects for many years to come.</p>   
                </div>
            </div>
        </section>
    </>
  )
}
