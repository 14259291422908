import React from 'react'

export default function Ready() {
  return (
    <>
<section className="dark-bg2 small-padding order-wrap" data-scrollax-parent="true">
    <div className="container">
        <div className="row">
            <div className="col-md-8">
                <h3>Ready To order Your Project ?</h3>
            </div>
            <div className="col-md-4"><a href="#sec5" className="btn flat-btn color-btn">Get In Touch</a> </div>
        </div>
    </div>
</section>
    </>
  )
}
