import React from 'react'

export default function Share() {
  return (
    <>
    <div className="share-wrapper isShare">
        <div className="share-title"><span>Share</span></div>
        <div className="close-share soa"><span>Close</span><i className="fal fa-times"></i></div>
        <div className="share-inner soa">
            <div className="share-container"></div>
        </div>
    </div>
    </>
  )
}
