import React from 'react'
import ReactCompareImage from 'react-compare-image';

export default function Service() {
  return (
    <>
        <section className="blue-bg sinsec-dec" id="sec3" data-scrollax-parent="true">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Blitz Service</div>
            <div className="container">
                <div className="section-title">
                    <h2>Our <span>SERVICES</span></h2>
                    <p className="content" style={{maxWidth: 'none'}}>Blitz VFX offers a range of visual effects services, including VFX clean up and Roto. VFX clean up involves removing unwanted elements from live-action footage, such as wires, rigs, and other distractions, to create a seamless and polished final product. This process is critical for creating convincing and believable visual effects that blend seamlessly with live-action footage.</p>
                    <p className="content" style={{maxWidth: 'none'}}> Roto, on the other hand, is a technique used to isolate individual elements from live-action footage for use in visual effects. This process involves tracing and defining the outline of an object or character, which can then be manipulated or replaced in post-production.</p>    
                    <p className="content" style={{maxWidth: 'none'}}>At Blitz VFX, the team of talented artists and technicians uses the latest technology and techniques to provide high-quality VFX clean up and Roto services that are accurate, efficient, and cost-effective. Whether you're working on a big-budget feature film or a smaller independent production, Blitz VFX has the skills and expertise to help bring your creative vision to life.</p>   
                </div>
            </div>
        </section>

        <section className="dark-bg sinsec-dec sinsec-dec2" data-scrollax-parent="true">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Vfx Paint</div>
            <div className="container">
                <div className="partcile-dec" data-parcount="100"></div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div>
                            <h2 style={{color:'#ededed',fontSize:'40px',fontWeight:'bold',marginBottom:'30px'}}>Vfx Paint/Prep </h2>
                            <p className="content-light">VFX Paint and Prep are important steps in the visual effects production process. VFX Paint involves removing unwanted elements from live-action footage, such as wires, rigs, and other distractions, to create a seamless and polished final product. This process is critical for creating convincing and believable visual effects that blend seamlessly with live-action footage.
                            </p>
                            <p className="content-light">
                                VFX Prep, on the other hand, involves preparing live-action footage for visual effects production. This can include tasks such as color correction, stabilization, and tracking to ensure that the live-action footage is ready for use in post-production.
                            </p>
                            <p className="content-light">
                                At Blitz VFX, the team of talented artists and technicians is well-versed in both VFX Paint and VFX Prep, and uses the latest technology and techniques to provide high-quality services that are accurate, efficient, and cost-effective. Whether you're working on a big-budget feature film or a smaller independent production, Blitz VFX has the skills and expertise to help bring your creative vision to life.</p>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="list-group">
                                        <li className="list-group-item"> Wire Removals</li>
                                        <li className="list-group-item"> Rig Removals</li>
                                        <li className="list-group-item"> Marker removals</li>
                                        <li className="list-group-item"> Seams Removals</li>
                                   </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group">
                                        <li className="list-group-item"> Beautyfix</li>
                                        <li className="list-group-item"> Camera/Reflection Removals</li>
                                        <li className="list-group-item"> Crew/Object Removals</li>
                                   </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="container" style={{marginTop:'150px'}}>
                            <ReactCompareImage leftImage="styles/images/compare/paint/1.jpeg" rightImage="styles/images/compare/paint/2.jpeg" />;
                        </div>
                    </div>
                </div>
            </div>
            <div className="half-bg-dec single-half-bg-dec" data-ran="12"></div>
            <div className="bg-parallax-module" data-position-top="70"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
            <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
            <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
            <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
            <div className="sec-lines"></div>
        </section>

        <section className="dark-bg sinsec-dec sinsec-dec2" data-scrollax-parent="true">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Rotoscopy</div>
            <div className="container">
                <div className="partcile-dec" data-parcount="100"></div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="partcile-dec" data-parcount="100"></div>
                        <div style={{marginTop:'150px'}}>
                            <ReactCompareImage leftImage="styles/images/compare/rotto/1.jpg" rightImage="styles/images/compare/rotto/2.jpeg" />;
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div>
                            <h2 style={{color:'#ededed',fontSize:'40px',fontWeight:'bold',marginBottom:'30px',paddingTop:'3rem'}}>Rotoscopy: </h2>
                            <p className="content-light">
                                VFX Rotoscopy is a process used in visual effects production to isolate individual elements from live-action footage for use in visual effects. This process involves tracing and defining the outline of an object or character in live-action footage, which can then be manipulated or replaced in post-production.
                            </p>
                            <p className="content-light">
                                Rotoscopy is a critical step in the visual effects production process, as it provides a foundation for other visual effects techniques, such as green screen compositing and 3D animation. The process requires a high level of accuracy and attention to detail, as even the slightest imperfections can be noticeable in the final product.
                            </p>
                            <p className="content-light">
                                At Blitz VFX, the team of talented artists and technicians is highly skilled in VFX Rotoscopy and uses the latest technology and techniques to provide high-quality services that are accurate, efficient, and cost-effective. Whether you're working on a big-budget feature film or a smaller independent production, Blitz VFX has the skills and expertise to help bring your creative vision to life
                            </p>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="list-group">
                                        <li className="list-group-item"> VFX Roto</li>
                                        <li className="list-group-item"> Stereo Roto</li>
                                   </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group">
                                        <li className="list-group-item">  Green/Blue Keying</li>
                                        <li className="list-group-item">  Matte Extraction</li>
                                   </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="half-bg-dec single-half-bg-dec" data-ran="12"></div>
            <div className="bg-parallax-module" data-position-top="70"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
            <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
            <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
            <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
            <div className="sec-lines"></div>
        </section>
        <section className="dark-bg sinsec-dec sinsec-dec2" data-scrollax-parent="true">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Matchmove</div>
            <div className="container">
                <div className="partcile-dec" data-parcount="100"></div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div>
                            <h2 style={{color:'#ededed',fontSize:'40px',fontWeight:'bold',marginBottom:'30px'}}>Matchmove</h2>
                            <p className="content-light">Matchmove tracking, also known as camera tracking or camera solving, is a process used in visual effects and 3D animation to replicate the movement of a real-world camera in a virtual 3D environment.
                            </p>
                            <p className="content-light">
                                The process involves tracking the movement of the camera in the live-action footage by identifying and analyzing patterns in the image. This information is then used to create a 3D camera that moves and rotates in the same way as the real-world camera.
                            </p>
                           <div className="row">
                                <div className="col-md-6">
                                    <ul className="list-group">
                                        <li className="list-group-item"> Camera Tracking</li>
                                        <li className="list-group-item"> Object/Layout Tracking</li>
                                        <li className="list-group-item"> 2D Tracking</li>
                                   </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="partcile-dec" data-parcount="100"></div>
                        <div className="container" style={{marginTop:'90px'}}>
                            <ReactCompareImage leftImage="styles/images/compare/mm/1.jpeg" rightImage="styles/images/compare/mm/2.jpeg" />;
                        </div>
                    </div>
                </div>
            </div>
            <div className="half-bg-dec single-half-bg-dec" data-ran="12"></div>
            <div className="bg-parallax-module" data-position-top="70"  data-position-left="20" data-scrollax="properties: { translateY: '-250px' }"></div>
            <div className="bg-parallax-module" data-position-top="40"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
            <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
            <div className="bg-parallax-module" data-position-top="95"  data-position-left="40" data-scrollax="properties: { translateY: '-550px' }"></div>
            <div className="sec-lines"></div>
        </section>
    </>
  )
}
