import React, { useEffect, useRef } from 'react';

export default function ComingSoon() {
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (videoRef.current) {
  //       const rect = videoRef.current.getBoundingClientRect();
  //       const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

  //       if (isVisible && videoRef.current.paused) {
  //         videoRef.current.play();
  //       } else if (!isVisible && !videoRef.current.paused) {
  //         videoRef.current.pause();
  //       }
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <section className="parallax-section fw-parallax" style={{ height: '110vh' }} id="sec4" data-scrollax-parent="true">
      <div className="bg par-elem" data-bg="styles/images/blitz/18.jpg" data-scrollax="properties: { translateY: '30%' }">
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}></div>
      </div>
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="video-promo-text fl-wrap mar-top">
              <h3>Show Reels</h3>
            </div>
          </div>
          <div className="col-md-9">
            <div className="video-box fl-wrap">
              <img src="styles/images/blitz/18.jpg" className="respimg" alt=""/>
              <video className="respimg" controls>
                <source src="styles/video/blitzFinal.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
