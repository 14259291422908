import React from 'react'

export default function Header() {
  return (
    <>
        <header className="main-header">
            <a className="logo-holder" href="#">
            <img src="styles/images/logo1.png"  alt="" />
            </a>
            <div className="nav-button but-hol">
                <span className="nos"></span>
                <span className="ncs"></span>
                <span className="nbs"></span>
                <div className="menu-button-text">Menu</div>
            </div>
            <div className="show-share showshare">
                <i className="fal fa-retweet"></i>
                <span>Share This</span>
            </div>
        </header>

        <div className="nav-overlay">
            <div className="tooltip color-bg">Close Menu</div>
        </div>

        <div className="nav-holder">
            <a className="header-logo" href="#"><img src="styles/images/logo1.png" style={{height:'50px'}} alt="" /></a> 
            <div className="nav-title"><span>Menu</span></div>
            <div className="nav-inner-wrap">
                <nav className="nav-inner sound-nav" id="menu" style={{height:'250px'}}>
                    <ul className="header--menu">
                        <li className="header--menu__item"><a className="scroll-link act-link" href="/">Home</a></li>
                        <li className="header--menu__item"><a className="scroll-link" href="#sec2">About</a></li>
                        <li className="header--menu__item"><a className="scroll-link" href="#sec3">Service</a></li>
                        <li className="header--menu__item"><a className="scroll-link" href="#sec4">Portfolio</a></li>
                        <li className="header--menu__item"><a className="scroll-link" href="#sec6">Team</a></li>
                        <li className="header--menu__item"><a className="scroll-link" href="#sec5">Contact</a></li>
                    </ul>
                </nav>
            </div>
        </div>

    </>
  )
}
